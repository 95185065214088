<template>
  <Page>
    <template v-slot:content>
      <Filters @onFiltersChanged="onFiltersChanged" />
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-btn
              icon
              :to="`/sellers/${item.id}`"
            >
              <v-icon
                color="blue lighten-1"
              >
                mdi-pencil-outline
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.last_authentication_date_time="{ item }">
            <span :title="item.last_authentication_date_time | date">
              {{ item.last_authentication_date_time | dateSimple }}
            </span>
          </template>
          <template v-slot:item.enabled="{ item }">
            <v-chip
              v-if="item.enabled"
              color="green"
            />
            <v-chip
              v-else
              color="red"
            />
          </template>
        </v-data-table>
      </v-sheet>
    </template>
  </Page>
</template>

<script>
import Filters from '@/views/Sellers/Filters'
import { getQueryString } from '@/helpers/queryString'
import Page from '@/components/core/Page.vue'

export default {
  name: 'SellersList',
  components: { Page, Filters },
  data() {
    return {
      request: null,
      table: {
        options: {
          page: +this.$route.query.page || 1,
          itemsPerPage: 30,
          sortBy: [],
          sortDesc: [],
        },
        headers: [
          {
            text: this.$i18n.t('views.sellers.headers.edit'),
            value: 'edit',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.sellers.headers.enabled'),
            value: 'enabled',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.sellers.headers.first_name'),
            value: 'first_name',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.sellers.headers.last_name'),
            value: 'last_name',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.sellers.headers.email'),
            value: 'email',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.sellers.headers.last_authentication_date_time'),
            value: 'last_authentication_date_time',
            sortable: true,
          },
        ],
        footerProps: {
          'items-per-page-options': [30],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      filters: {
        name: this.$route.query.name,
        email: this.$route.query.email,
        enabled: this.$route.query.enabled,
      },
    }
  },
  computed: {
    queryString: function () {
      return 'sellers?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
      'table.options.page': function(value) {
        this.$router.replace({ name: 'SellersList', query:
            { ...this.filters, page : value } }
        )
      },
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'SellersList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      this.$axios.get(this.queryString)
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
          this.result.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
  },
}
</script>

<style scoped>
</style>
