<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="filters.last_name"
            hide-details
            clearable
            outlined
            dense
            :label="$t('views.sellers.filters.last_name')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="filters.email"
            hide-details
            clearable
            outlined
            dense
            :label="$t('views.sellers.filters.email')"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filters.enabled"
            hide-details
            clearable
            outlined
            dense
            item-text="label"
            item-value="value"
            :items="values.enabled"
            :label="$t('views.sellers.filters.enabled')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { debounce } from '@/helpers/debounce'

export default {
  name: 'Filters',
  data() {
    return {
      filters: {
        name: this.$route.query.name,
        email: this.$route.query.email,
        enabled: this.$route.query.enabled,
      },
      values: {
        enabled: [
          { label: this.$i18n.t('views.sellers.enum.enabled.yes'), value: 1 },
          { label: this.$i18n.t('views.sellers.enum.enabled.no'), value: 0 },

        ],
      },
    }
  },
  watch: {
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
